<template>
	<div>
        <quill-editor 
            v-model="content" 
            ref="myQuillEditor" 
            :options="editorOption" 
            @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
            @change="onEditorChange($event)">
           
        </quill-editor>
        <!-- 富文本内容 -->
        <div class="text">
            <!-- {{content}} -->
          
        </div>
        <!-- <button v-on:click="saveHtml">保存</button> -->
        <!-- <el-upload
  class="upload-demo"
  :on-success="handleAvatarSuccess"
  action="http://192.168.0.196:8002/api/upload/uploadFile"
  :limit="1"
>
  <el-button size="small" type="primary">点击上传</el-button>
</el-upload> -->

    </div>
</template> 
<script>
    import { quillEditor } from 'vue-quill-editor'
    import $ from 'jquery'//先安装jquery
    export default{

        props:{
            contentT:{
                type:String,
                default:''
            }
        },

        data(){
            return {
                content:null,
                editorOption:{},editorOption:{
              placeholder: "请在这里输入",
              modules:{
              //富文本导航栏功能定义 
               toolbar:[
                          ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
                          ['blockquote', 'code-block'],     //引用，代码块
                          [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
                          [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
                          [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
                          [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
                          [{ 'direction': 'rtl' }],             // 文本方向
                          [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
                          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
                          [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
                          [{ 'font': [] }],     //字体
                          [{ 'align': [] }],    //对齐方式
                          ['clean'],    //清除字体样式
                          ['image']    //上传图片、上传视频
                          ]
                      }
                },
            }
        },
        computed: {
	       editor() {
	            return this.$refs.myQuillEditor.quill;
	        },
	    },
        created(){
                // console.log(this.contentT)
                // console.log('0-0-0-0-')
                this.content = this.contentT
        },

        watch:{
            contentT(newVal,oldVal){
                this.content = newVal
            }
        },

        methods:{
            // handleAvatarSuccess(res){
            //     console.log(res.data.url)
            //     let temp = res.data.url
            //     this.content = `<img src= ${temp} ></img>` 
            // },

            onEditorBlur(){//失去焦点事件
            },
            onEditorFocus(){//获得焦点事件
            },
            onEditorChange(){//内容改变事件
            	//console.log(this.content)
        		//$('.text').html(this.content)
                    this.$emit("getMarkDownVal",this.content)
                    // alert(this.content);
            },
           // getMarkDownVal(){},
	        //   alert(this.content);

	        
           async saveHtml (event) {           
              var that = this;
               console.log(that.content)
               let form = new FormData()
               form.append('file',that.content)
             const {data: res} = await 
             that.$http.post("/upload/uploadFile",form)
             .then(function (response) {
            // console.log(response.data.data);
             if (response.data.code == 200) {
             that.$message({
                  message: '保存成功！！！',
                  duration: 1500,
                  type: 'success'
                })
             }else {
                that.$message({
                  message: response.data.message,
                  duration: 1500,
                  type: 'error'
                })
                }
          });
      }
    },
        }
    
</script>  
